import { graphql, useStaticQuery } from "gatsby";
import moment from 'moment';
import { TBEvent } from './strapiTypes';

interface matchesProps {
  eventPrice: string,
  eventDate: string,
  eventFinalSignupDate: string,
  eventTitle: string,
  eventName: string,
  eventLocation: string,
}

export const replaceMatches = (match: string): string => {
  const { strapi } = useStaticQuery(graphql`
      query {
        strapi {
          tbEvents(where: { active: true }, limit: 1) {
            price
            date
            finalSignupDate
            title
            name
            location
          }
        }
      }
    `);

  const event: TBEvent = strapi.tbEvents[0];

  const matches: matchesProps = {
    eventPrice: event?.price,
    eventDate: moment(event?.date).format('dddd DD MMMM'),
    eventFinalSignupDate: moment(event?.finalSignupDate).format('DD MMMM'),
    eventTitle: event?.title,
    eventName: event?.name,
    eventLocation: event?.location,
  };

  const result: string = matches[match];

  return result || `{${match}}`;
};
