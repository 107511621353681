import React from 'react';
import { Form, Button, Row, Col, Checkbox } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { useQuery, gql } from '@apollo/client';
import { useStep } from '../../../hooks';
import { Industry } from '../../../utils';
import formStyles from '../../formComponents/formComponents.module.css';

interface IndustryData {
  industries: [Industry],
};

interface FormData {
  interestingIndustries: string[],
};

const INDUSTRIES = gql`
  query {
    industries(order_by: {title: asc}) {
      id
      title
      image {
        formats
      }
    }
  }
`;

const Industries = () => {
  const { data } = useQuery<IndustryData>(INDUSTRIES, { context: { clientName: "hasura" } });
  const { initialValues, onFinish } = useStep<FormData>(
    (me) => ({
      interestingIndustries: me?.interestingIndustries.map(industry => industry.id) || [],
    }),
  )

  return (
    <Form
      className={formStyles.form}
      initialValues={initialValues}
      onFinish={(values) => onFinish(values as FormData)}
    >
      <h3><span className="turquoise">Welke sectoren vind jij interessant?</span></h3>
      <p>
        Vink hieronder de sectoren aan waar jij wel in zou willen werken. Deze informatie gebruiken
        wij om de beste matches voor jou te zoeken!
      </p>

      <Form.Item name="interestingIndustries" className="checkbox-images">
        <Checkbox.Group>
          <Row gutter={32}>
            {data && data.industries.map(industry => (
              <Col key={industry.id} xs={12} sm={8} md={6}>
                <Checkbox value={industry.id.toString()}>
                  <div className="checkbox-images-overlay-wrap">
                    <div className="checkbox-images-overlay"></div>
                    <CheckCircleTwoTone twoToneColor="#00ff7e" />
                  </div>
                  <img src={industry.image?.formats?.industry?.url} alt="alt" />
                  <p>{industry.title}</p>
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          Opslaan
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Industries;
