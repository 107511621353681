import { Button } from 'antd';
import React from 'react';
import starx3 from '../../images/starx3.png';
import { PickerHeader } from '../';
import styles from './prePicker.module.css';
import { useStaticQuery, graphql } from 'gatsby';
import moment from 'moment';

const PreEventsPicker = () => {
  const { strapi } = useStaticQuery(graphql`
    query {
      strapi {
        tbEvents(where: { active: true }, limit: 1) {
          title
          date
          finalSignupDate
        }
        programItemTypes(where: { programItems: { tbEvent: { active: true }}}) {
          id
        }
      }
    }
  `);

  const event = strapi.tbEvents[0];

  return <div className={styles.preEventsPicker}>
    <PickerHeader />

    <div className="container-sm">
      <h3><span className="turquoise">Toppertje</span></h3>
      <p>Het {event.title} op {moment(event.date).format('DD MMMM')} zal bestaan uit {strapi.programItemTypes.length} dagonderdelen. We vragen je nu om per dagonderdeel je voorkeur uit te spreken.</p>
      <p>Ook vragen we je om je adres in te vullen, zodat we eventuele merchandise van de deelnemende bedrijven naar je kunnen sturen.</p>
      <p>Doe dit vóór {moment(event.finalSignupDate).format('DD MMMM')}, dan gaan wij daarna aan de slag om de beste match voor jou te vinden!</p>

      <p className={styles.center}>
        <img src={starx3} alt="star" />
      </p>
      <p className={styles.center}>
        <Button
          type="primary" href="/student/programma-invullen/"
          block
          style={{ maxWidth: 400 }}>
          Aan de slag
        </Button>
      </p>
    </div>
  </div>
};

export default PreEventsPicker;
