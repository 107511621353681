import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import { pick } from '../../utils';
import { useAuth } from '../../hooks';
import { PickerHeader } from '../';
import formStyles from '../formComponents/formComponents.module.css'
import confirmItemStyles from '../formComponents/confirmItem.module.css';

interface FormData {
  postalCode: string,
  streetName: string,
  houseNumber: string,
  city: string,
  qompasVacancyGuide: boolean,
  qompasCvCheck: boolean,
};

const Address = () => {
  const { me, updateMe } = useAuth();
  const initialValues = useMemo(() => {
    return me && pick(me, ['postalCode', 'streetName', 'houseNumber', 'city']);
  }, [me]);

  const onSubmit = async ({ postalCode, streetName, houseNumber, city, qompasVacancyGuide, qompasCvCheck }: FormData) => {
    await updateMe({
      variables: {
        input: {
          postalCode,
          streetName,
          houseNumber,
          city,
          qompasVacancyGuide,
          qompasCvCheck,
        },
      },
    });
    navigate('/student/programma-adres-compleet/');
  };

  return <div>
    <PickerHeader />
      <div className="container-sm">
        {me ? <Form
          className={formStyles.form}
          layout="vertical"
          initialValues={{
            ...initialValues,
            qompasVacancyGuide: false,
            qompasCvCheck: false,
          }}
          onFinish={(data) => onSubmit(data as FormData)}
        >
          <h3><span className="turquoise">Adres toevoegen</span></h3>
          <p>Voeg je adres toe om eventuele merchandise van de deelnemende bedrijven te ontvangen.</p>

          <Form.Item
            label="Wat is je straatnaam?"
            name="streetName"
            rules={[{ required: true, message: 'Vul je straatnaam in.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Huisnummer (incl. toevoeging)"
            name="houseNumber"
            rules={[{ required: true, message: 'Vul je huisnummer en toevoeging in.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Postcode"
            name="postalCode"
            rules={[{ required: true, message: 'Vul je postcode in.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Plaatsnaam"
            name="city"
            rules={[{ required: true, message: 'Vul je Plaatnaam in.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item style={{ marginTop: '40px' }}>
            <Button
              type="primary"
              htmlType="submit"
              block
            >
              Opslaan
            </Button>
          </Form.Item>

          <Form.Item style={{ marginTop: '40px' }}>
            <Button
              htmlType="submit"
              block
            >
              Overslaan
            </Button>
          </Form.Item>

      </Form> : null}
    </div>
  </div>
};

export default Address;
