import React, { useState } from 'react';
import { Form, Button, Input, Checkbox } from 'antd';
import { ConfirmItem, PickerHeader } from '../';
import formStyles from '../formComponents/formComponents.module.css';
import { useAuth, useFormFieldProvider } from '../../hooks';
import { navigate } from '@reach/router';
import IBAN from 'iban';
import styles from './payment.module.css';
import { useStaticQuery, graphql } from 'gatsby';
import moment from 'moment';



interface FormData {
  bankAccountName: string,
  iban: string,
  promoCode: string,
  paymentAgreed: boolean,
};

const Payment = () => {
  const { strapi } = useStaticQuery(graphql`
    query {
      strapi {
        tbEvents(where: { active: true }, limit: 1) {
          collectionDate
          price
        }
      }
    }
  `);
  const { collectionDate, price } = strapi.tbEvents[0];

  const { me, updateMe } = useAuth();
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const { onFieldsChange, useFormField, setFieldsValue } = useFormFieldProvider(form);
  const [paymentAgreed] = useFormField(['paymentAgreed']);

  const onSubmit = async ({ bankAccountName, iban, promoCode }: FormData) => {
    if (!paymentAgreed) {
      setError(true);
      return;
    }

    await updateMe({
      variables: {
        input: {
          participation: 'CONFIRMED',
          bankAccountName,
          iban,
          promoCode,
        },
      },
    });
    navigate('/student/deelname-compleet/');
  };

  return <div>
    <PickerHeader />
    <div className="container-sm">
      <h3><span className="turquoise">Inschrijfgeld betalen</span></h3>
      <p>Deelname aan Techniek Bedrijven Event wordt met een eenmalige incasso van €{price} bevestigd. Het bedrag zal omstreeks {moment(collectionDate).format('DD MMMM')} worden afgeschreven.</p>

      <Form
        className={formStyles.form}
        layout="vertical"
        form={form}
        onFinish={(data) => onSubmit(data as FormData)}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          label="Volledige naam rekeninghouder"
          name="bankAccountName"
          rules={[{ required: true, message: 'Vul je volledige naam in' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="IBAN"
          name="iban"
          rules={[
            { required: true, message: 'Vul je volledige IBAN-nr in, dit begint met NL' },
            { validator: (_, value) => IBAN.isValid(value) ? Promise.resolve() : Promise.reject('Vul een geldig IBAN in') },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Kortingscode (optioneel)"
          name="promoCode"
        >
          <Input />
        </Form.Item>

        <ConfirmItem title="Ik ga akkoord met eenmalige automatische machtiging.">
          <Form.Item
            name="paymentAgreed"
            valuePropName="checked"
            noStyle
          >
            <Checkbox onChange={() => setError(false)} />
          </Form.Item>
        </ConfirmItem>
        {error && <p className={styles.error}>Om verder te gaan moet er ingestemd worden met de eenmalige incasso.</p>}

        <Form.Item style={{ marginTop: '40px' }}>
          <Button
            type="primary"
            block
            htmlType="submit"
          >
            Opslaan
          </Button>
        </Form.Item>
      </Form>
    </div>
  </div>
};

export default Payment;
