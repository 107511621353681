import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useAuth } from '../../hooks';
import { gql, useMutation } from '@apollo/client';
import { PickerHeaderDynamic } from '../';
import { dateIsBeforeNow } from '../../utils';
import styles from './inhouseDay.module.css';
import { navigate } from 'gatsby';

interface inhouseDayProps {
  id: string,
  title: string,
  companyName: string,
  slug?: string,
  finalSignupDate: Date,
}

const ENROLL = gql`
  mutation enroll($input: enrollUserInput!) {
    enroll(input: $input) {
      id
    }
  }
`;

const InHouseDayEnroll = ({ id, slug, title, companyName, finalSignupDate }: inhouseDayProps) => {
  const { me, refetch, loading } = useAuth();
  const [enroll] = useMutation(ENROLL, { onCompleted: refetch });
  const enrolled = useMemo(() => Boolean(me?.enrollments?.find(e => e.inhouse_day?.id === id)), [me]);
  const openForParticipation = useMemo(() => dateIsBeforeNow(finalSignupDate), [finalSignupDate]);

  const onEnroll = async (id: string) => {
    enroll({
      variables: {
        input: {
          inhouse_day: id,
        },
      }
    });
    navigate('/student/deelname-compleet/');
  };
  const backLink = `/inhousedagen/${slug}/`;

  return (
    <div>
      <PickerHeaderDynamic backLink={backLink} />
      <div className="container-sm">
        {me ?
          <>
            <h3><span className="turquoise">Oh, kom er eens kijken</span></h3>
            <p>Om je aanmelding voor {title} | {companyName} compleet te maken, moet je je anonieme CV naar ons mailen. Wij  sturen alle CV’s dan in 1x naar het bedrijf op. Zo kan ze onbevooroordeeld kiezen wie wordt uitgenodigd voor de inhousedag!</p>
            <p>Stuur hierna dus jouw CV zonder naam, adres, e-mailadres en telefoonnummer vóór de uiterste datum van inschrijving naar <a href="mailto:info@techniekbedrijven.nl">info@techniekbedrijven.nl</a>.</p>
            <p style={{ fontSize: '96px', margin: 0, textAlign: 'center' }}>🔭</p>
          </> : ''
        }
      </div>

      <div className="container-sm">
        {me && openForParticipation && !loading && !enrolled && <div className={styles.button}>
          <Button
            type="primary"
            block
            style={{ margin: '20px 0 20px 0' }}
            onClick={() => onEnroll(id)}
          >Meld je nu aan</Button>
        </div>}
      </div>
    </div>
  );
};

export default InHouseDayEnroll;
