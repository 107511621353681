import React from 'react';
import moment from 'moment';
import { Form } from 'antd';
import { StudentTeam, pick } from '../../../utils';
import { useStep } from '../../../hooks';
import formStyles from '../../formComponents/formComponents.module.css';
import useLinkedForms from '../../../hooks/useLinkedForms';
import ItemsOverview from '../../formComponents/itemsOverview';
import StudentTeamItem from '../../formComponents/studentTeamItem';
import StudentTeamModal from './studentTeamModal';

interface FormData {
  studentTeam: Partial<StudentTeam>[];
}

const StudentTeams = () => {
  const { initialValues, onFinish } = useStep<FormData>(
    me => ({
      studentTeam: me.studentTeam?.map(studentTeam => ({
        ...pick(studentTeam, ['name', 'position', 'employmentType', 'hoursPerWeek', 'beginDate', 'endDate']),
        beginDate: studentTeam.beginDate ? moment(studentTeam.beginDate) : undefined,
        endDate: studentTeam.endDate ? moment(studentTeam.endDate) : undefined,
      })),
    }),
    ({ studentTeam }) => ({
      studentTeam: studentTeam.map(team => ({
        ...team,
        hoursPerWeek: Number.parseFloat(team.hoursPerWeek as string),
        beginDate: team.beginDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        endDate: team.endDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
      })),
    })
  );

  const { form, linkedForm, onFormFinish, openEdit, openNew, shouldUpdate, destroyItem } = useLinkedForms<
    FormData['studentTeam']
  >('studentTeam', onFinish);

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Form
        {...form}
        className={formStyles.form}
        initialValues={initialValues}
      >
        <h3>
          <span className="turquoise">Studententeam</span>
        </h3>

        <p>
          Vertel ons wat voor studenteamwerk je gedaan hebt. <u>Begin bij je meest relevante ervaring.</u>
        </p>

        <ItemsOverview
          field="studentTeam"
          name={{ singular: 'Studentteam', plural: 'studententeams' }}
          ItemComponent={StudentTeamItem}
          {...{ openEdit, openNew, shouldUpdate }}
          destroyItem={destroyItem}
        />
      </Form>

      <StudentTeamModal {...linkedForm} />
    </Form.Provider>
  );
};

export default StudentTeams;
