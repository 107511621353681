export const readCmsToken = () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  try {
    const { exp } = JSON.parse(window.atob(token.split('.')[1]));
    if (new Date().getTime() / 1000 > exp) return null;

    return token;
  } catch {
    writeCmsToken(null);
    return null;
  }
};

export const writeCmsToken = (token: string | null) => {
  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
  }
};
