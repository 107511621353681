import React from 'react';
import { Button } from 'antd';
import { Timeline } from '../';
import { PickerHeader } from '../';
import { graphql, useStaticQuery } from 'gatsby';

const CancelCompleet = () => {
  const { strapi } = useStaticQuery(graphql`
    query {
      strapi {
        timeline {
          CancelComplete {
            title
            steps {
              title
              description
              id
              check
            }
          }
        }
      }
    }
  `);
  const { timeline } = strapi;

  return <div>
    <PickerHeader />
    <div className="container-sm">
      <h3 style={{ marginLeft: 20 }}><span className="turquoise">{timeline?.CancelComplete.title}</span></h3>
    </div>

    <Timeline
      steps={timeline?.CancelComplete?.steps}
      version="signup"
      forceVertical
    />

    <div className="container-sm">
      <Button
        block
        type="primary"
        href="/student/account/"
      >
          Terug naar mijn account
      </Button>
    </div>
  </div>;
}

export default CancelCompleet;
