import React from 'react';
import styles from './todoPanel.module.css';

const images = {
  brokenHeart: require('../../images/broken-heart.png'),
  building: require('../../images/building.png'),
  cup: require('../../images/cup.png'),
  greenCheck: require('../../images/green-check.png'),
  painter: require('../../images/painter.png'),
  star: require('../../images/star.png'),
  warningSign: require('../../images/warning-sign.png'),
};

type icon = "brokenHeart" | "building" | "cup" | "greenCheck" | "painter" | "star" | "warningSign";

const TodoPanel = ({ title, titleIcon, description, children }: { title?: string, titleIcon: icon, description?: string, children?: React.ReactNode }) => {
  return <div className={styles.todoPanel}>
    {title && <p><img src={images[titleIcon]} /> {title}</p>}
    {description && <p>{description}</p>}
    {children}
  </div>
};

export default TodoPanel;
