import React from 'react';
import { navigate } from 'gatsby';
import { Spin } from 'antd';
import { useAuth, useActiveModal } from '../../hooks';


const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isAuthenticated, loading } = useAuth();
  const { setActiveModal } = useActiveModal();

  const notLoggedIn = async () => {
    setActiveModal('login').catch(() => navigate('/'));
    return null;
  };

  if (!isAuthenticated && !loading) {
    notLoggedIn();
  }

  return (
    <Spin spinning={loading}>
      <Component {...rest} />
    </Spin>
  );
}

export default PrivateRoute;
