import { useQuery, useMutation, gql } from '@apollo/client';
import { useMemo } from 'react';
import moment from 'moment';
import { navigate } from '@reach/router';
import { readCmsToken, writeCmsToken, User, UserInput } from '../utils';

interface MeData {
  me: User;
}

const ME = gql`
  query {
    me {
      id
      username
      email
      firstName
      lastName
      phoneNumber
      postalCode
      streetName
      houseNumber
      city
      qompasVacancyGuide
      qompasCvCheck
      cv {
        id
      }
      avatar {
        url
        formats
      }
      step
      progress
      isComplete
      interestingIndustries {
        id
        title
      }
      highSchoolAverageMark
      highSchoolStartYear
      highSchoolEndYear
      highSchoolName
      highSchoolType
      linkedInProfile
      studies {
        id
        type
        education {
          id
          name
        }
        educationName
        educationalInstitution {
          id
          name
        }
        educationalInstitutionName
        finished
        beginDate
        endDate
        numberOfECs
        partiallyAbroad
        averageMark
        minor
        nominal
        monthsBehind
      }
      hasJobs
      hasCommittees
      hasBoards
      hasTopSport
      hasOwnBusiness
      hasVolunteerWork
      hasStudentTeam
      jobs {
        id
        companyName
        jobTitle
        type
        beginDate
        endDate
        hoursPerWeek
        partiallyAbroad
      }
      committees {
        id
        association
        name
        position
        employmentType
        beginDate
        endDate
        hoursPerWeek
      }
      boards {
        id
        type
        name
        position
        employmentType
        beginDate
        endDate
        hoursPerWeek
      }
      topSport {
        associationName
        type
        scale
      }
      ownBusiness {
        id
        name
        beginDate
        endDate
        hoursPerWeek
      }
      volunteerWork {
        associationName
        position
        hoursPerMonth
        partiallyAbroad
      }
      studentTeam {
        id
        name
        position
        employmentType
        hoursPerWeek
        beginDate
        endDate
      }
      skills {
        id
        name
      }
      preferredProgramItems {
        id
        company {
          name
        }
        name
        location
        description
        startsAt
        programItemType {
          color
          id
          name
        }
      }
      participation
      enrollments {
        id
        participation
        inhouse_day {
          id
          slug
          finalSignupDate
          startDate
          endDate
          time
          location
          title
          company {
            name
          }
        }
        tb_event {
          id
          title
        }
        preferredProgramItems {
          id
          company {
            name
          }
          name
          location
          description
          startsAt
          programItemType {
            color
            id
            name
          }
        }
      }
    }
  }
`;

const LOGIN = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        username
        email
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_ME = gql`
  mutation($input: editUserInput) {
    updateMe(input: $input) {
      postalCode
      streetName
      houseNumber
      city
      qompasVacancyGuide
      qompasCvCheck
      bankAccountName
      iban
      promoCode
      reason
      step
      progress
      isComplete
      interestingIndustries {
        id
        title
      }
      highSchoolAverageMark
      highSchoolStartYear
      highSchoolEndYear
      highSchoolName
      highSchoolType
      linkedInProfile
      studies {
        id
        type
        educationalInstitution {
          id
          name
        }
        education {
          id
          name
        }
        finished
        beginDate
        endDate
        nominal
        monthsBehind
      }
      hasJobs
      hasCommittees
      hasBoards
      hasTopSport
      hasOwnBusiness
      hasVolunteerWork
      hasStudentTeam
      jobs {
        id
        companyName
        jobTitle
        type
        beginDate
        endDate
        hoursPerWeek
        partiallyAbroad
      }
      committees {
        id
        association
        name
        position
        employmentType
        beginDate
        endDate
        hoursPerWeek
      }
      boards {
        id
        type
        name
        position
        employmentType
        beginDate
        endDate
        hoursPerWeek
      }
      topSport {
        associationName
        type
        scale
      }
      ownBusiness {
        name
        beginDate
        endDate
        hoursPerWeek
      }
      volunteerWork {
        associationName
        position
        hoursPerMonth
        partiallyAbroad
      }
      studentTeam {
        name
        position
        employmentType
        hoursPerWeek
        beginDate
        endDate
      }
      skills {
        id
      }
      participation
    }
  }
`;

const useAuth = () => {
  const { client, loading, data, updateQuery, refetch } = useQuery<MeData>(ME, {
    errorPolicy: 'ignore',
  });

  const me = data?.me;
  const isAuthenticated = !!me;

  if (!loading && !isAuthenticated && readCmsToken()) {
    writeCmsToken(null);
  }

  const [login] = useMutation(LOGIN, {
    onCompleted({ login }) {
      writeCmsToken(login.jwt);
      client.resetStore();
    },
  });

  const logout = () => {
    writeCmsToken(null);
    navigate('/');
    client.resetStore();
  };

  const setToken = (jwt: string) => {
    writeCmsToken(jwt);
    client.resetStore();
  };

  const [updateMe] = useMutation<{ updateMe: User; input: UserInput }>(UPDATE_ME, {
    onCompleted({ updateMe }) {
      updateQuery(() => ({ me: updateMe }));
    },
  });

  const formMe = useMemo(() => {
    if (!me) return me;
    return {
      ...me,
      interestingIndustries: me.interestingIndustries.map(industry => industry.id),
      studies: me.studies.map(study => {
        const { __typename, ...studyWithoutTypename } = study;
        return {
          ...studyWithoutTypename,
          educational_institution: study.educational_institution?.id,
          education: study.education?.id,
          beginDate: moment(study.beginDate),
          endDate: moment(study.endDate),
        };
      }),
      skills: me.skills.map(skill => skill.id),
    };
  }, [me]);

  return {
    me,
    formMe,
    updateMe,
    loading,
    isAuthenticated,
    login,
    logout,
    setToken,
    refetch,
  };
};

export default useAuth;
