import React, { useMemo } from 'react';
import { Row, Col } from "antd"
import { useQuery, gql, useMutation } from "@apollo/client"
import moment from "moment"
import { Layout } from "../"
import { Link, navigate } from "gatsby"
import styles from "./enroll.module.css"
import { InhouseDayProps, TBEventProps } from "../../utils/strapiTypes"
import LogoCircle from "../../images/logo-circle.svg"
import { tbEventOpenForSignup } from '../../utils/tbEvent';

interface EventData {
  inhouseDays: [InhouseDayProps]
  tbEvents: [TBEventProps]
}

const EVENTS = gql`
  query($date: String) {
    tbEvents(limit: 1, sort: "active:desc,created_at:desc") {
      id
      title
      finalSignupDate
      active
    }
    inhouseDays(where: { finalSignupDate_gte: $date }) {
      id
      title
      slug
      company {
        name
        logo {
          url
        }
      }
      image {
        url
      }
    }
  }
`

const ENROLL = gql`
  mutation enroll($input: enrollUserInput!) {
    enroll(input: $input) {
      id
    }
  }
`

const Enroll = () => {
  const date = moment(new Date()).format("YYYY-MM-DD")
  const { data: dataEvents } = useQuery<EventData>(EVENTS, {
    variables: { date },
  })

  const tbEvent = dataEvents?.tbEvents?.[0]
  const tbEventOpenToEnroll = useMemo(
    () => Boolean(tbEventOpenForSignup(tbEvent) && tbEvent?.active),
    [tbEvent]
  );

  function navigateToProfile() {
    navigate("/student/account/")
  }

  const [enroll] = useMutation(ENROLL, { onCompleted: navigateToProfile })
  const onEnroll = async () => {
    if (!tbEvent || !tbEventOpenToEnroll) {
      return
    }

    await enroll({
      variables: {
        input: {
          tb_event: tbEvent.id,
        },
      },
    })
  }

  return (
    <Layout>
      <section style={{ padding: "32px 0" }}>
        <div className="container">
          <h3>
            <span className="turquoise">Meld je aan</span>
          </h3>
          <p>Klik op het evenement waarvoor je je wilt aanmelden.</p>

          {tbEvent ? (
            <Row gutter={32}>
              <Col key={`tb-event-${tbEvent.id}`} xs={12} sm={8} md={6}>
                <div
                  className={`${styles.enrollLink} ${
                    tbEventOpenToEnroll ? null : styles.inactiveEvent
                  }`}
                  onClick={onEnroll}
                >
                  <figure className={styles.enrollTile}>
                    <img
                      src={LogoCircle}
                      alt={tbEvent.title}
                      className={tbEventOpenToEnroll ? null : styles.inactiveImage}
                    />
                    {tbEventOpenToEnroll ? null : (
                      <span className={styles.ribbon}>Binnenkort</span>
                    )}
                  </figure>
                  <strong>{tbEvent.title}</strong>
                </div>
              </Col>
            </Row>
          ) : null}

          {dataEvents?.inhouseDays?.length ?? 0 > 0 ? (
            <>
              <h4>Inhousedagen</h4>

              <Row gutter={32}>
                {dataEvents &&
                  dataEvents.inhouseDays.map(inhouseDay => (
                    <Col key={inhouseDay.id} xs={12} sm={8} md={6}>
                      <Link
                        to={`/inhousedagen/${inhouseDay.slug}/enroll`}
                        className={styles.enrollLink}
                      >
                        <figure className={styles.enrollTile}>
                          <img
                            src={inhouseDay.company.logo.url}
                            alt={`${inhouseDay.title} | ${inhouseDay.company.name}`}
                          />
                        </figure>
                        <strong>{`${inhouseDay.title} | ${inhouseDay.company.name}`}</strong>
                      </Link>
                    </Col>
                  ))}
              </Row>
            </>
          ) : null}
        </div>
      </section>
    </Layout>
  )
}

export default Enroll
