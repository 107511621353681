import React, { useState, useMemo } from 'react';
import classnames from 'classnames/bind';
import { Grid, Row, Col } from 'antd';
import moment from 'moment';
import AnimateHeight from 'react-animate-height';
import { CaretRightOutlined } from '@ant-design/icons';
import styles from './companyEvent.module.css';
import '../layout/layout.css';

const cx = classnames.bind(styles);

interface CompanyEvent {
  programItemName: string,
  startsAt: Date,
  location: string,
  description: string,
  programItemTypeName: string,
  programItemTypeColor: string,
  companyName: string
};

const companyEvents = ({
  programItemName,
  startsAt,
  location,
  description,
  programItemTypeName,
  programItemTypeColor,
  companyName = '',
}: CompanyEvent) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [active, setActive] = useState(false);

  return  <div className={cx('event')}>
    <div className={cx('faded', { show: !active && !screens.md })}></div>
    <div className={styles.eventHeader}>
      <Row>
        <Col flex="auto">
          <span
            className="tag"
            style={{ backgroundColor: programItemTypeColor }}
          >
            {programItemTypeName}
          </span>
        </Col>
        <Col flex="30px">
          {screens.md ? null : <span className={styles.caret} onClick={() => setActive(!active)}>
            <CaretRightOutlined className={cx({ expanded: active })} />
          </span>}
        </Col>
      </Row>
      <p style={{ marginTop: 12 }}><b>
        {companyName && `${companyName} |`} {programItemName}</b></p>
      <p>{moment(startsAt).format('DD-MM-YYYY kk:mm')}</p>
      <p>{location}</p>
    </div>
    <AnimateHeight
      duration={500}
      height={(active || screens.md) ? 'auto' : 37}
    >
      {description}
    </AnimateHeight>
  </div>
};

export default companyEvents;
