import React, { useMemo } from 'react';
import {
  Form, InputNumber, Button, Input, Radio, Row, Col,
} from 'antd';
import { useAuth, useStep } from '../../../hooks';
import { pick } from '../../../utils';
import formStyles from '../../formComponents/formComponents.module.css';

interface FormData {
  highSchoolAverageMark?: number,
  highSchoolStartYear?: number,
  highSchoolEndYear?: number,
}

const HighSchool = () => {
  const { initialValues, onFinish } = useStep<FormData>(me =>
    pick(me, ['highSchoolAverageMark', 'highSchoolStartYear', 'highSchoolEndYear', 'highSchoolName', 'highSchoolType'])
  );

  return (
    <Form
      className={formStyles.form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <h3><span className="turquoise">Die goeie ouwe tijd</span></h3>
      <p>
        Je gemiddelde eindcijfer van je middelbare school is van invloed op hoe goed je matcht met
        een bepaald bedrijf.
      </p>

      <Form.Item
        label="Wat was je gemiddelde eindcijfer op de middelbare school?"
        name="highSchoolAverageMark"
        validateTrigger="onSubmit"
        rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
      >
        <InputNumber
          type="number"
        />
      </Form.Item>

      <Form.Item
        label="Wat was de naam van je middelbare school?"
        name="highSchoolName"
        validateTrigger="onSubmit"
        rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Welke type opleiding volgde je hier?"
        name="highSchoolType"
        rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
      >
        <Radio.Group className="checkbox-normal">
          <Row gutter={10}>
            <Col xs={12} flex="0 1 150px">
              <Radio value="VWO">VWO</Radio>
            </Col>
            <Col xs={12} flex="0 1 150px">
              <Radio value="HAVO">HAVO</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="In welk jaar ben je begonnen?"
        name="highSchoolStartYear"
        validateTrigger="onSubmit"
        rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
      >
        <InputNumber type="number" />
      </Form.Item>

      <Form.Item
        label="In welk jaar ben je geslaagd?"
        name="highSchoolEndYear"
        validateTrigger="onSubmit"
        rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
      >
        <InputNumber type="number" />
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          Opslaan
        </Button>
      </Form.Item>
    </Form>
  );
};

export default HighSchool;
