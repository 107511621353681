import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Button, Col, Row } from 'antd';
import { gql, useMutation } from '@apollo/client';
import moment from 'moment';
import calendar from '../../images/calendar.png';
import clock from '../../images/clock.png';
import locationB from '../../images/locationB.png';
import { useActiveModal, useAuth } from '../../hooks';
import styles from './inhouseDay.module.css';
import { dateIsBeforeNow } from '../../utils';
import { navigate } from 'gatsby';

interface inhouseDayProps {
  id: string,
  title: string,
  startDate: Date,
  endDate: Date,
  time: string,
  location: string,
  finalSignupDate: Date,
  company: {
    name: string,
    logo: {
      url: string,
    }
  },
  slug?: string,
  isDetailPage?: boolean
}

const InhouseDay = ({ id, title, startDate, endDate, time, location, finalSignupDate, company, slug, isDetailPage }: inhouseDayProps) => {
  const { me, refetch, loading } = useAuth();
  const enrolled = useMemo(() => Boolean(me?.enrollments?.find(e => e.inhouse_day?.id === id)), [me]);
  const openForParticipation = useMemo(() => dateIsBeforeNow(finalSignupDate), [finalSignupDate]);
  const { setActiveModal } = useActiveModal();

  return <div className={styles.inhouseDay}>
    {company && <>
        <img className={styles.logo} src={company?.logo?.url} />
        <p>{title} | {company.name}</p>
      </>
    }
    <p><img src={calendar} />{moment(startDate).format('DD MMMM')} {endDate && ` t/m ${moment(endDate).format('DD MMMM')}`}</p>
    <p><img src={clock} />{time}</p>
    <p><img src={locationB} />{location}</p>

    {!loading && enrolled && <div className={styles.closeDate}>Je hebt je hiervoor ingeschreven</div>}
    {!loading && openForParticipation && !enrolled && <div className={styles.closeDate}>Inschrijving sluit op: {moment(finalSignupDate).format('DD MMMM')}</div>}
    {!loading && !openForParticipation && !openForParticipation && <div className={styles.closeDate}>De inschrijving is gesloten</div>}

    {!isDetailPage && <p className={styles.readMore}><a href={`/inhousedagen/${slug}`}>Lees meer</a></p>}
    {me && openForParticipation && !loading && !enrolled && <div className={styles.button}>
      <Button
        type="primary"
        style={{ margin: 'auto' }}
        onClick={() => navigate(`/inhousedagen/${slug}/enroll`)}
      >Meld je nu aan</Button>
    </div>}

    {!me && openForParticipation && !loading && <div className={styles.button}>
      <Button
        type="primary"
        onClick={() => setActiveModal('register')}
      >
        Aanmelden
      </Button>
    </div>}


  </div>
};

export default InhouseDay;
