import React, { useMemo } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { navigate } from '@reach/router';
import { gql, useQuery } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import { Breadcrumb, TodoPanel } from '../';
import { useAuth, useMicrocopy } from '../../hooks';
import { Participation, Enrollment } from '../../utils/strapiTypes';
import { CompanyEvent, Layout } from '../';
import '../layout/layout.css';
import styles from './events.module.css';

const QUERY = gql`
query {
  match {
    finished
    programItems {
      location
      name
      numberOfStudents
      description
      company {
        name
      }
      startsAt
      programItemType {
        color
        name
      }
    }
  }
}
`;

const Events = () => {
  const { me, updateMe } = useAuth();
  const m = useMicrocopy();
  const { strapi } = useStaticQuery(graphql`
    query {
      strapi {
        tbEvents(where: { active: true }, limit: 1) {
          id
          title
          date
          finalSignupDate
          location
          studentsCanPickProgramItems
        }
      }
    }
  `);

  const { loading, data } = useQuery(QUERY);
  const event = strapi.tbEvents[0];
  const activeEnrollment: Enrollment | undefined = me?.enrollments.find(enrollment => {
    return enrollment?.tb_event?.id === event.id;
  })

  const participation: Participation = activeEnrollment?.participation || 'UNCONFIRMED';
  const matches = data?.match?.programItems;
  const matchingFinished = data?.match?.finished;
  const studentsCanPickProgramItems = event.studentsCanPickProgramItems;

  const orderedProgramItems = useMemo(() => {
    return activeEnrollment?.preferredProgramItems?.reduce((acc, current) => {
      if (Object.keys(acc).length === 0 && acc.constructor === Object) return { [current.programItemType.name]: [current] };
      const sub = acc[current.programItemType.name] || [];
      return {
        ...acc,
        [current.programItemType.name]: [...sub, current],
      };
    }, {}) || {};
  }, [me]);

  const state = useMemo(() => {
    if (participation === "DENIED") return "UNSUBSCRIBE_RECEIVED";
    if (!studentsCanPickProgramItems) return "EVENT_NOT_READY"
    if (studentsCanPickProgramItems && !activeEnrollment) return "NOT_ENROLLED";
    if (studentsCanPickProgramItems && !matchingFinished && Object.keys(orderedProgramItems).length === 0) return "PICK_PREFERRED";
    if (studentsCanPickProgramItems && !matchingFinished && orderedProgramItems) return "PREFERRED_PICKED";
    if (matchingFinished && matches.length > 0) return "MATCHES_READY"
    return "IMPOSSIBLE_TO_MATCH";
  }, [participation, matchingFinished, matches, studentsCanPickProgramItems, orderedProgramItems]);

  const changeParticipation = async (participation: Participation, href: string) => {
    navigate(`/student/deelname-${href}/`);
  };

  return <Layout>
    <div className={`${styles.events} container-sm`}>
      <Breadcrumb />
      <h2>Evenementen</h2>

      <h3><span className="turquoise">{moment(event.date).format('dddd DD MMMM')}</span></h3>
      <p><b>{event.title}</b></p>

      {state === "EVENT_NOT_READY" && <p style={{ marginBottom: 200 }}>{m('EventNotReady')}</p>}

      {state === "NOT_ENROLLED" && (
        <Button type="primary">
          <Link to="/student/deelname-aanmelden/">Aanmelden</Link>
        </Button>
      )}

      {state === "PICK_PREFERRED" && <div>
          <TodoPanel
            title={m('CompaniesEstablishedTitle')}
            titleIcon="building"
            description={m('CompaniesEstablishedDescription')}
          >
            <Button
              style={{ width: '100%', maxWidth: 400, margin: 'auto', display: 'block' }}
              href="/student/voor-programma/"
              type="primary"
            >
              {m('CompaniesEstablishedButtonLabel')}
            </Button>
          </TodoPanel>
        <p style={{ fontSize: 18 }}><b>{m('EventsYourFavoriteProgramItems')}</b></p>
        <p>{m('EventsNoFavoriteProgramItemsPicked')}</p>
      </div>}

      {state === "PREFERRED_PICKED" && <div>
        <TodoPanel
          title={m('FavoriteProgramItemsPickedTitle')}
          titleIcon="star"
          description={m('FavoriteProgramItemsPickedDescription')}
        />
        <p style={{ fontSize: 18 }}><b>{m('EventsYourFavoriteProgramItems')}</b></p>
        {Object.entries(orderedProgramItems).map(([key, value], i) => <div key={i} className={styles.preference}>
            <p><span className="tag" style={{ background: value[0].programItemType.color }}>{key}</span></p>
              {value.map(item => <p key={item.id}>{item.company.name} | {item.name}</p>)}
          </div>
        )}
        <p>{m('FavoriteProgramItemsPickedSub')}</p>
      </div>}

      {state === "MATCHES_READY" && participation !== "CONFIRMED" && <div>
        <TodoPanel
          title={m('MatchesReadyTitle')}
          titleIcon="cup"
          description={m('MatchesReadyDescription')}
        >
          <Button
            style={{ width: '100%', maxWidth: 400, margin: 'auto', display: 'block' }}
            onClick={() => changeParticipation("CONFIRMED", "bevestiging")}
            type="primary"
          >
            {m('MatchesReadyButtonLabel')}
          </Button>
          <a className={styles.centerLink} onClick={() => changeParticipation("DENIED", "annuleren")}>{m('MatchesReadyCancelLink')}</a>
        </TodoPanel>
      </div>}

      {state === "MATCHES_READY" && participation === "CONFIRMED" && <div>
        <TodoPanel
          title={m('ParticipationConfirmedTitle')}
          titleIcon="greenCheck"
          description={m('ParticipationConfirmedDescription')}
        />
      </div>}

      {state === "MATCHES_READY" && <Row justify="start" gutter={20}>
        {matches.map((match, i) => <Col key={i} style={{ marginTop: 20 }} md={12}>
            <CompanyEvent
              programItemName={match.name}
              startsAt={match.startsAt}
              location={match.location}
              description={match.description}
              programItemTypeName={match.programItemType.name}
              programItemTypeColor={match.programItemType.color}
              companyName={match.company.name}
            />
          </Col>
        )}
      </Row>}

      {state === "UNSUBSCRIBE_RECEIVED" && <TodoPanel
          title={m('UnsubscribeReceivedTitle')}
          titleIcon="brokenHeart"
          description={m('UnsubscribeReceivedDescription')}
      />}

      {state === "IMPOSSIBLE_TO_MATCH" && <TodoPanel
          title={m('NoMatchPossibleTitle')}
          titleIcon="warningSign"
          description={m('NoMatchPossibleDescription')}
        >
          <Button
            style={{ width: '100%', maxWidth: 400, margin: 'auto', display: 'block' }}
            href="/student/profiel/"
            type="primary"
          >
            {m('NoMatchPossibleButtonLabel')}
          </Button>
        </TodoPanel>}
    </div>
  </Layout>
};

export default Events;
