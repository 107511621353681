import React from 'react';
import { Link } from 'gatsby';
import { Form, Button } from 'antd';
import check from '../../images/check.svg';
import { PickerHeader } from '../';
import styles from './confirm.module.css';
import { useStaticQuery, graphql } from 'gatsby';

const Confirm = () => {
  const { strapi } = useStaticQuery(graphql`
    query {
      strapi {
        tbEvents(where: { active: true }, limit: 1) {
          price
        }
      }
    }
  `);
  const { price } = strapi.tbEvents[0];

  return <div>
    <PickerHeader />
    <div className="container-sm">
      <h3><span className="turquoise">Tof je dat je erbij bent</span></h3>
      <p>Bevestig nu je deelname door het betalen van de deelnemerskosten (€{price}) met een eenmalige incasso.</p>
      <p><b>Wat zit hierbij inbegrepen?</b></p>
      <p className={styles.usp}><span><img src={check} /></span>Case study</p>
      <p className={styles.usp}><span><img src={check} /></span>Lunch</p>
      <p className={styles.usp}><span><img src={check} /></span>Twee bedrijfspresentaties</p>
      <p className={styles.usp}><span><img src={check} /></span>Bedrijvenmarkt</p>
      <p className={styles.usp}><span><img src={check} /></span>Borrel</p>

      <p><b>Let op!</b> We houden jouw plek maar beperkte tijd vast. Betaal dus nu, voordat je jouw favoriete bedrijven misloopt!</p>

      <Form.Item style={{ marginTop: '40px' }}>
        <Button
          type="primary"
          block
          href="/student/deelname-machtiging/"
        >
          Inschrijfgeld betalen
        </Button>
      </Form.Item>

      <Link className={styles.cancel} to="/student/deelname-annuleren/">
        Ik kan er toch niet bij zijn
      </Link>
    </div>
  </div>
};

export default Confirm;
