import React from 'react';
import { Form, Input, Button, Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import { pick } from '../../../utils';
import formStyles from '../../formComponents/formComponents.module.css';
import { useStep } from '../../../hooks';

interface FormData {
  name: string;
  beginDate: moment.Moment;
  endDate: moment.Moment;
  hoursPerWeek: number | string;
}

const OwnBusiness = () => {
  const { initialValues, onFinish } = useStep<Partial<FormData>>(
    ({ ownBusiness }) => ({
      ...pick(ownBusiness, ['name', 'hoursPerWeek']),
      beginDate: ownBusiness?.beginDate ? moment(ownBusiness.beginDate) : undefined,
      endDate: ownBusiness?.endDate ? moment(ownBusiness.endDate) : undefined,
    }),
    ({ name, beginDate, endDate, hoursPerWeek }) => ({
      ownBusiness: {
        name,
        beginDate: beginDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        endDate: endDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        hoursPerWeek: Number.parseFloat(hoursPerWeek as string),
      },
    })
  );

  return (
    <Form className={formStyles.form} initialValues={initialValues} onFinish={values => onFinish(values as FormData)}>
      <h3>
        <span className="turquoise">Eigen bedrijf</span>
      </h3>

      <Form.Item label="Naam bedrijf" name="name" rules={[{ required: true, message: 'Dit is een verplicht veld' }]}>
        <Input type="text" />
      </Form.Item>

      <Row gutter={10}>
        <Col xs={12}>
          <Form.Item
            label="Begin datum"
            name="beginDate"
            rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
          >
            <DatePicker className="datepickerStyle" format="MMMM YYYY" picker="month" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label="Eind datum (verwacht)"
            name="endDate"
            rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
          >
            <DatePicker className="datepickerStyle" format="MMMM YYYY" picker="month" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button block type="primary" htmlType="submit">
          Opslaan
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OwnBusiness;
