import React from 'react';
import { Form } from 'antd';
import { UserBoard, pick } from '../../../utils';
import { useStep } from '../../../hooks';
import formStyles from '../../formComponents/formComponents.module.css';
import useLinkedForms from '../../../hooks/useLinkedForms';
import ItemsOverview from '../../formComponents/itemsOverview';
import BoardItem from '../../formComponents/boardItem';
import BoardsModal from './boardsModal';
import moment from 'moment';


interface FormData {
  boards: Partial<UserBoard>[]
}

const Boards = () => {
  const { initialValues, onFinish } = useStep<FormData>(
    me => ({
      boards: me.boards?.map(board => ({
        ...pick(board, ['id', 'name', 'position', 'type', 'employmentType', 'hoursPerWeek']),
        beginDate: board.beginDate ? moment(board.beginDate) : undefined,
        endDate: board.endDate ? moment(board.endDate) : undefined,
      })),
    }),
    ({ boards }) => ({
      boards: boards.map(board => ({
        ...board,
        beginDate: board.beginDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        endDate: board.endDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        hoursPerWeek: Number.parseFloat(board.hoursPerWeek as string),
      })),
    })
  );

  const {
    form,
    linkedForm,
    onFormFinish,
    openEdit,
    openNew,
    shouldUpdate,
    destroyItem,
  } = useLinkedForms<FormData['boards']>('boards', onFinish);

  return (
    <Form.Provider onFormFinish={onFormFinish} >
      <Form
        {...form}
        className={formStyles.form}
        initialValues={initialValues}
      >
        <h3><span className="turquoise">Bestuur</span></h3>

        <ItemsOverview
          field="boards"
          name={{ singular: 'Bestuur', plural: 'Besturen' }}
          ItemComponent={BoardItem}
          {...{ openEdit, openNew, shouldUpdate }}
          destroyItem={destroyItem}
        />

      </Form>

      <BoardsModal
        {...linkedForm}
      />
    </Form.Provider>
  );
}

export default Boards;
