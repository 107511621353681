import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';
import { tbEventOpenForSignup } from '../utils/tbEvent';

interface EventData {
  finalSignupDate: Date;
  studentsCanPickProgramItems: boolean;
}

interface QueryResult {
  tbEvents: [EventData];
}

const EVENT = gql`
  query {
    tbEvents(where: { active: { _eq: true } }) {
      finalSignupDate
      studentsCanPickProgramItems
    }
  }
`;

const useEvent = () => {
  const { loading, data } = useQuery<QueryResult>(EVENT, {
    errorPolicy: 'ignore',
    context: { clientName: 'hasura' },
  });

  const tbEvent = data?.tbEvents?.[0];

  const openForSignup = useMemo(() => {
    return tbEventOpenForSignup(tbEvent);
  }, [tbEvent]);

  return {
    loading,
    openForSignup,
    tbEvent,
  };
};

export default useEvent;
