export const colors = ['turquoise', 'orange', 'green'] as const;

export type Color = typeof colors[number];

export type TopSportScale = 'regional' | 'national' | 'international';

export type EmploymentType = 'PARTTIME' | 'FULLTIME' | 'BOTH';

export type Participation = 'UNCONFIRMED' | 'CONFIRMED' | 'DENIED';

export type ButtonType = 'primary' | 'default' | 'dashed' | 'text' | 'link';

export interface StrapiFooter {
  address: string,
  address2: string,
  phone: string,
  email: string,
  terms: string,
  socialPlatforms: [SocialIcon]
}

export type SocialPlatform = 'facebook' | 'twitter' | 'instagram' | 'linkedin';

export interface SocialIcon {
  id: string,
  platform: SocialPlatform,
  url: string,
}

export interface StrapiMenu {
  items: [StrapiMenuItem | StrapiMenuGroup],
}

export interface StrapiMenuItem {
  __typename: 'STRAPI_ComponentMenuMenuItem',
  id: string,
  label: string,
  url: string,
}

export interface StrapiMenuGroup {
  __typename: 'STRAPI_ComponentMenuGroup',
  id: string,
  label: string,
  children: [StrapiMenuItem],
}

export interface StrapiFileFormat {
  ext: string,
  has: string,
  name: string,
  width: number,
  height: number,
  size: number,
  url: string,
  mime: string,
}

export interface StrapiFile {
  id: string
  ext: string,
  has: string,
  name: string,
  width: number,
  height: number,
  size: number,
  url: string,
  mime: string,
  formats: {
    droplet: StrapiFileFormat,
    thumbnail: StrapiFileFormat,
    industry: StrapiFileFormat,
  },
}

export interface StrapiVideoEmbed {
  __typename: 'STRAPI_ComponentContentVideoEmbed'
  title?: string,
  embedCode?: string,
  thumbnail?: StrapiFile,
}

export interface Homepage {
  title?: string,
  subtitle?: string,
  banner?: StrapiFile,
  structure?: blocks['blocks'],
  tb_event?: Partial<TBEvent>,
}

export interface TBEvent {
  id: string,
  title: string,
  price: string,
  location: string,
  date: Date,
  finalSignupDate: Date,
  registerLabel: string,
  banner: StrapiFile,
  name: string,
  structure: blocks['blocks'],
}

export interface blocks {
  blocks: [
    {
      __typename: 'STRAPI_ComponentContentWhiteLogoBar',
      logos: Array<{
        logo: StrapiFile,
        link: string,
      }>,
    } |
    {
      __typename: 'STRAPI_ComponentContentDropletSectionList',
      title: string,
      sections: Array<{
        title: string,
        subTitle: string,
        paragraph: string,
        image: StrapiFile,
        buttonLabel: string,
        buttonLink: string,
      }>
    } |
    {
      __typename: 'STRAPI_ComponentContentSpotlight',
      title: string,
      image: StrapiFile,
      buttonLabel: string,
      buttonLink: string,
      tb_event: TBEvent,
      inhouse_days: InhouseDayProps[]
    } |
    {
      __typename: 'STRAPI_ComponentContentTestimonialList',
      title: string,
      testimonials: Array<{
        id: string,
        image: StrapiFile,
        excerpt: string,
        quote: string,
        study?: string,
        age?: number,
        name: string,
      }>,
    } |
    {
      __typename: 'STRAPI_ComponentContentCallToAction'
      title?: string,
      subTitle?: string,
      buttonLabel?: string,
      buttonLink?: string,
      buttonType?: ButtonType | 'underline',
      color?: Color,
    } |
    {
      __typename: 'STRAPI_ComponentContentTimeline'
      title: string,
      steps: Array<{
        title: string,
        description: string,
      }>,
      conclusion?: string,
    } |
    StrapiVideoEmbed
  ],
}

export interface StaticPage extends blocks {
  slug: string,
  title: string,
}

export interface InhouseDayProps extends blocks {
  id: string,
  slug: string,
  title: string,
  startDate: Date,
  endDate: Date,
  time: string,
  location: string,
  finalSignupDate: Date,
  image: StrapiFile,
  description: string,
  company: {
    name: string,
    logo: {
      url: string,
    },
  },
}

export interface TBEventProps {
  id: string
  title: string
  active: boolean
  finalSignupDate: Date
}

export interface Faq {
  title: string,
  categories: Array<{
    id: string,
    name: string,
    questions: Array<{
      id: string,
      question: string,
      answer: string,
    }>,
  }>,
}

export interface ProgramItemType {
  id: string,
  name: string,
  color: string
}

export interface ProgramItem {
  id: string
  name: string
  description: string
  startsAt: string
  programItemType: ProgramItemType
  location: string
  numberOfStudents: number
}

export interface StudentTeam {
  name: string;
  position: string;
  employmentType: EmploymentType;
  hoursPerWeek: number | string;
  beginDate: moment.Moment;
  endDate: moment.Moment;
}

export interface Company {
  id: string,
  name: string,
  slug: string,
  street: string,
  houseNumber: string,
  postalCode: string,
  city: string,
  description: string,
  logo: StrapiFile,
  industry: Industry,
  generalStats: {
    numberOfEmployees?: number,
    numberOfCountries?: number,
    numberOfBranches?: number
  },
  jobsVideo: StrapiVideoEmbed,
  jobStats: {
    numberOfVacancies?: number,
    numberOfInternships?: number
  },
  studentProfile: {
    description?: string,
    usps?: Array<{
      usp: string
    }>
  },
  programItems: ProgramItem[]
}

export interface Industry {
  id: string,
  title: string,
  image: StrapiFile,
  companies: Company[],
}

export type WizardStep = 'INDUSTRIES' | 'LINKED_IN' | 'HIGH_SCHOOL' | 'STUDIES' | 'SIDE_ACTIVITIES' | 'JOBS' | 'COMMITTEES' | 'BOARDS' | 'TOP_SPORT' | 'OWN_BUSINESS' | 'VOLUNTEER_WORK' | 'STUDENT_TEAM' | 'SKILLS' | 'COMPLETE';

export interface Education {
  id: string,
  name: string,
  type: 'WO_BACHELOR' | 'HBO_BACHELOR' | 'WO_MASTER' | 'TRANSITION',
  educationalInstitutions: [EducationalInstitution],
}

export interface EducationalInstitution {
  id: string,
  name: string,
  educations: [Education],
}

export interface UserStudy {
  __typename: string,
  id: string,
  type: 'WO_BACHELOR' | 'HBO_BACHELOR' | 'WO_MASTER' | 'TRANSITION' | 'ABROAD' | 'OTHERWISE',
  education: Education,
  educationName?: string,
  educationalInstitution: EducationalInstitution,
  educationalInstitutionName?: string,
  finished: boolean,
  minor: string,
  beginDate: moment.Moment
  endDate: moment.Moment
  numberOfECs: number,
  partiallyAbroad: boolean,
  averageMark: number,
  nominal: boolean,
  monthsBehind: number,
}

export interface VolunteerWork {
  associationName: string,
  position: string,
  hoursPerMonth: string | number,
  partiallyAbroad: boolean,
}

export interface UserJob {
  id: string,
  companyName: string,
  jobTitle: string,
  type: 'SIDEJOB' | 'INTERNSHIP',
  hoursPerWeek: number | string,
  partiallyAbroad: boolean
  beginDate: moment.Moment
  endDate: moment.Moment
}

export interface UserCommittee {
  id: string,
  association: string,
  name: string,
  position: string,
  employmentType: 'PARTTIME' | 'FULLTIME'
  hoursPerWeek: number | string,
  beginDate: moment.Moment
  endDate: moment.Moment
}

export interface UserSkill {
  id: string,
  name: string
}

export interface UserBoard {
  id: string,
  type: 'STUDENT_ASSOCIATION' | 'STUDY_ASSOCIATION' | 'FOUNDATION' | 'ORGANIZATION' | 'DISPUTATION' | 'MASTER_BOARD' | 'OTHER_ASSOCIATION',
  name: string,
  position: string,
  employmentType: 'PARTTIME' | 'FULLTIME',
  hoursPerWeek: number | string,
  beginDate: moment.Moment
  endDate: moment.Moment
}

export interface PreferredProgramItem {
  company: {
    name: string
  },
  id: string,
  name: string,
  location: string,
  description: string,
  startsAt: Date,
  programItemType: {
    color: string,
    id: string,
    name: string,
  }
}

export interface InhouseDay {
  id: string,
  slug: string,
  title: string,
  finalSignupDate: Date,
  startDate: Date,
  endDate: Date,
  time: string,
  location: string,
  company: {
    name: string,
  },
}

export interface Enrollment {
  id: string,
  participation: Participation,
  tb_event: {
    id: string,
    title: string,
  },
  inhouse_day: InhouseDay,
  bankAccountName: string,
  iban: string,
  promoCode: string,
  cancelReason: string,
  user: {
    id: string
  }
}

export interface User {
  id: string,
  username: string,
  email: string,
  firstName: string,
  lastName: string,
  postalCode: string,
  streetName: string,
  houseNumber: string,
  city: string,
  qompasVacancyGuide: boolean,
  qompasCvCheck: boolean,
  cv: StrapiFile,
  reason: string,
  avatar: StrapiFile,
  phoneNumber: string,
  step: WizardStep,
  progress: number | null,
  isComplete: boolean,
  interestingIndustries: Industry[],
  highSchoolAverageMark: number,
  highSchoolStartYear: number,
  highSchoolEndYear: number,
  highSchoolName: string,
  highSchoolType: 'HAVO' | 'VWO',
  linkedInProfile: string,
  studies: UserStudy[],
  hasJobs: boolean,
  hasCommittees: boolean,
  hasBoards: boolean,
  hasTopSport: boolean,
  hasOwnBusiness: boolean,
  hasVolunteerWork: boolean,
  hasStudentTeam: boolean,
  jobs: UserJob[],
  committees: UserCommittee[],
  boards: UserBoard[],
  topSport: {
    associationName: string,
    type: string,
    scale: TopSportScale,
  },
  ownBusiness?: {
    name: string,
    hoursPerWeek: number | string,
    beginDate: moment.Moment
    endDate: moment.Moment
  },
  volunteerWork: VolunteerWork[],
  studentTeam: StudentTeam[],
  skills: UserSkill[],
  preferredProgramItems: PreferredProgramItem[],
  enrollments: [Enrollment],
  participation: Participation
}
export interface UserInput {
  step: WizardStep,
  progress: number,
  interestingIndustries: Array<string>,
  highSchoolAverageMark: number,
  highSchoolStartYear: number,
  highSchoolEndYear: number,
  studies: Array<{
    id: string,
    type: 'WO_BACHELOR' | 'HBO_BACHELOR' | 'WO_MASTER' | 'TRANSITION' | 'ABROAD' | 'OTHERWISE',
    education?: string,
    educationName?: string,
    educationalInstitution?: string,
    educationalInstitutionName?: string,
    finished: boolean,
    beginDate: Date,
    endDate: Date,
    numberOfECs: number,
    partiallyAbroad: boolean,
    averageMark: number,
    nominal: boolean,
    monthsBehind: number,
  }>,
  hasJobs: boolean,
  hasCommittees: boolean,
  hasBoard: boolean,
  hasTopSport: boolean,
  hasOwnBusiness: boolean,
  hasVolunteerWork: boolean,
  hasStudentTeam: boolean,
  jobs: UserJob[],
  committees: UserCommittee[],
  boards: UserBoard[],
  topSport: {
    associationName: string,
    type: string,
    scale: TopSportScale,
  },
  ownBusiness: {
    name: string,
    hoursPerWeek: number | string,
  },
  volunteerWork: VolunteerWork[],
  studentTeam: StudentTeam[],
  skills: string[]
  preferredProgramItems: Array<string>,
}

export interface ProgramItemType {
  id: string,
  name: string
}
