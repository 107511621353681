import React from 'react';
import { Button } from 'antd';
import { Timeline } from '../';
import { PickerHeader } from '../';
import { useStaticQuery, graphql } from 'gatsby';

const AddressCompleet = () => {
  const { strapi } = useStaticQuery(graphql`
    query {
      strapi {
        timeline {
          AddressComplete {
            title
            steps {
              title
              description
              id
              check
            }
          }
        }
      }
    }
  `);
  const { timeline } = strapi;

  return <div>
    <PickerHeader />

    <div className="container-sm">
      <h3 style={{ marginLeft: 20 }}><span className="turquoise">{timeline.AddressComplete.title}</span></h3>
    </div>

    <Timeline
      steps={timeline.AddressComplete.steps}
      version="signup"
      forceVertical
      />

    <div className="container-sm">
      <Button
        block
        type="primary"
        href="/student/programma/"
        >
        Terug naar Evenementen
      </Button>
    </div>
  </div>;
}

export default AddressCompleet;
