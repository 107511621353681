// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bedrijven-tsx": () => import("./../../../src/pages/bedrijven.tsx" /* webpackChunkName: "component---src-pages-bedrijven-tsx" */),
  "component---src-pages-event-tsx": () => import("./../../../src/pages/event.tsx" /* webpackChunkName: "component---src-pages-event-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inhousedagen-tsx": () => import("./../../../src/pages/inhousedagen.tsx" /* webpackChunkName: "component---src-pages-inhousedagen-tsx" */),
  "component---src-pages-student-tsx": () => import("./../../../src/pages/student.tsx" /* webpackChunkName: "component---src-pages-student-tsx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-static-page-tsx": () => import("./../../../src/templates/staticPage.tsx" /* webpackChunkName: "component---src-templates-static-page-tsx" */)
}

