import React, { useState } from 'react';
import { Alert, Form, Input, Button, Row, Col, Modal } from 'antd';
import { SendOutlined, WarningOutlined } from '@ant-design/icons';
import { useMutation, gql } from '@apollo/client';
import { useActiveModal } from '../../hooks';
import { extractGraphqlErrorMessages, validateOnce } from '../../utils';
import formStyles from '../formComponents/formComponents.module.css'

interface FormData {
  email: string;
};

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

const ForgotPassword = () => {
  const { setActiveModal } = useActiveModal();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const [errors, setErrors] = useState([] as any);
  const [form] = Form.useForm();

  const onSubmit = async ({ email }: FormData) => {
    try {
      forgotPassword({ variables: { email } });
    } catch (exception) {
      const error = extractGraphqlErrorMessages(exception);
      error.forgotPassword ? setErrors(error.forgotPassword.codes) : setErrors(['other']);
      form.validateFields();
    }
    finally{
      setErrors(['send']);
    }
  };

  return (
    <Form
      className={formStyles.form}
      layout="vertical"
      form={form}
      onFinish={data => onSubmit(data as FormData)}
    >
      <h3><span className="turquoise">Oeps, wachtwoord kwijt?</span></h3>
      {errors.includes('send') && <Alert type="success" message="We hebben je een e-mail gestuurd waarmee je je wachtwoord kunt aanpassen." showIcon icon={<SendOutlined />} />}
      {errors.includes('other') && <Alert type="error" message="Er is iets misgegaan bij je aanvraag." showIcon icon={<WarningOutlined />} /> }
      {errors.includes('MessageRejected') && <Alert type="error" message="Het versturen van het resetmailtje is mislukt." showIcon icon={<WarningOutlined />} /> }
      <Form.Item
        name="email"
        label="Wat is je e-mailadres?"
        rules={[
          { required: true, message: 'Vul je e-mailadres in' },
          { type: 'email', message: 'Dit lijkt niet op een e-mailadres' },
          validateOnce(errors, setErrors, 'Auth.form.error.user.not-exist', 'Er is geen account met dit e-mailadres'),
        ]}
      >
        <Input
          type="email"
        />
      </Form.Item>
      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          Nieuw wachtwoord instellen
        </Button>
      </Form.Item>
      <p style={{ textAlign: 'center' }}>
        <a onClick={() => setActiveModal('login') }>Terug naar inloggen</a>
      </p>
    </Form>
  );
};

const ForgotPasswordModal = () => {
  const { modal, setActiveModal } = useActiveModal();

  return (
    <Modal
      bodyStyle={{ backgroundColor: 'var(--secondary-500)' }}
      destroyOnClose={true}
      footer={null}
      onCancel={() => setActiveModal(null)}
      visible={modal === 'forgot-password'}
      width={500}
    >
      <ForgotPassword />
    </Modal>
  );
};

export default ForgotPasswordModal;
